<template>
	<div
		:class="{unavailabe: !isAvailable}"
		class="archive-card">
		<!-- :style="" -->
		<div
			:style="{ 'background-image': 'url(' +  imgUrl + ')' }"
			class="inner"
		>
			<h3 class="centering">{{ cardTitle }}</h3>
		</div>
	</div>
</template>

<script>
export default {
	name: "ArchiveCard",
	props: {
		cardTitle: {
			type: String,
			required: true
		},
		bgImg: {
			type: Number,
			required: true,
			default() {
				return ""
			}
		},
		archLink: {
			type: String,
			required: false,
			default() {
				return ""
			}
		}
	},
	data() {
		return {
			imgUrl: ''
		}
	},
	created() {
		this.getImage()
	},
	computed: {
		isAvailable() {
			return (this.archLink.length > 1)
		}
	},
	methods: {
		createPath(i) {
			return require(`../assets/images/${i}`)
		},
		getImage() {
			if (this.bgImg > 0) {
				this.$http
					.get(`${this.$store.state.rootUrl}assets/${this.bgImg}`)
					.then((response) => {
						this.imgUrl = response.data['o:asset_url']
					})
					.catch(err => {
						this.err = err
						this.imgUrl = `https://picsum.photos/id/1073/600/800`
					})
			} else {
				this.imgUrl = `https://picsum.photos/id/1073/600/800`
			}
		}
	}
}
</script>

<style scoped lang="scss">
	.archive-card {
		margin: 1rem;
		background: white;
		box-shadow: 2px 4px 25px rgba(0, 0, 0, .1);
		border-radius: 2px;
		overflow: hidden;
		transition: all .2s linear;
		height: 25vw;
		width: 22vw;
		cursor: pointer;

			.inner {
				background-size: cover;
				background-repeat: no-repeat;
				padding: 1% 0;
				height: 100%;
				min-height: 300px;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
			}

	}

	.unavailabe {
		// opacity: 0.5!important;
	}

	.archive-card:not(.unavailabe) {
    cursor: pointer;
	}

	.archive-card:hover {
		box-shadow: 2px 8px 45px rgba(0, 0, 0, .15);
		transform: translate3D(0, -2px, 0);
	}

	h3 {
		color: $dark;
		height: 80px;
		font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (1600 - 300)));
		background-color:	#fffc;
		margin-bottom: -2%;
		padding: 0 2%;
	}

	img {
		width: 120px;
		height: 300px;
	}

	@media screen and (min-width: 600px) {
	.archive-card {
		flex: 1 1 calc(50% - 2rem);
		max-width: 50%;
  }

}

@media screen and (min-width: 960px) {
	.archive-card {
		flex: 1 1 calc(33% - 2rem);
		max-width: 25vw;
	}
}

@media(max-width: 1200px) {
	.archive-card{
		height: 28vw;

		h3 {
			font-size: 20px;
		}
	}
}

@media(max-width: 1025px) {
	h3 {
		font-size: 20px;
	}
}

@media(max-width: 970px) {
	.archive-card {
		flex-direction: column;
		width: 97%;
		height: 45vh;
		overflow: hidden;
	}
}

@media (max-width: 775px) {
	h3 {
		font-size: 18px;
	}

		.archive-card {
			width: 90%;
			height: 50vh;

			h3 {
				font-size: 18px;
			}

		}

}

</style>
